import React, { useState, useEffect } from 'react';
import MaxWidthWrapper from '../../component/MaxWidthWrapper';
import { Input } from '../../component/ui/input';
import { Button, buttonVariants } from '../../component/ui/button';
import { toast } from '../../component/ui/use-toast'; // Assuming toast is available in your project
import { Icons } from "../../assests/Icons";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useGetAuctionsQuery, useFilterListingsMutation } from '../../slices/apiSlices/carListingApiSlice';
import CarCards from './CarCards';
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";


const FoundCar = () => {
  const [status, setStatus] = useState("all");
  const [page, setPage] = useState(1);
  const limit = 8;
  const [cars, setCars] = useState([]);

  const {
    data: Listings,
    isLoading,
    isError,
    error,
  } = useGetAuctionsQuery({ status, page, limit });

  const [filterListings] = useFilterListingsMutation();

  useEffect(() => {
    if (Listings?.auctions) {
      setCars(Listings.auctions);
    }

    if (isError) {
      toast({
        title: "Error",
        description: error?.data?.message || "Failed to load auctions.",
        status: "error",
        variant: "destructive",
      });
    }
  }, [Listings, isError, error]);




  const onSubmit = async (data) => {
    try {
      const filteredData = await filterListings({ ...data, page, limit }).unwrap();
      setCars(filteredData?.auctions || []);
    } catch (filterError) {
      toast({
        title: "Error",
        description: filterError?.data?.message || "Failed to filter listings.",
        status: "error",
        variant: "destructive",
      });
    }
  };

  return (
    <div className='overflow-x-hidden'>
      <div className='font-inter gap-6'>
        <div className='flex flex-col mt-10'>
          <div className='text-3xl font-semibold'>
            {`${cars.length} Cars Found`}
          </div>

          

          {/* Car Cards */}
          <div className='mt-4'>
            <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-3 gap-4">
              {cars?.length > 0 ? cars.map((car) => (
                <CarCards key={car.id} car={car} />
              )) : (
                <p>No cars found</p>
              )}
            </div>
          </div>

          {/* Pagination */}
          <div className='mt-6'>
            <Pagination
              currentPage={page}
              totalPages={Math.ceil((Listings?.total || 1) / limit)}
              onPageChange={(newPage) => setPage(newPage)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoundCar;
export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePrevious = () => {
      if (currentPage > 1) onPageChange(currentPage - 1);
    };
  
    const handleNext = () => {
      if (currentPage < totalPages) onPageChange(currentPage + 1);
    };
  
    return (
      <div className='flex justify-center items-center space-x-4'>
        <Button onClick={handlePrevious} disabled={currentPage === 1}>
          <MdOutlineKeyboardDoubleArrowLeft />
        </Button>
        <span>Page {currentPage} of {totalPages}</span>
        <Button onClick={handleNext} disabled={currentPage === totalPages}>
          <MdOutlineKeyboardDoubleArrowRight />
        </Button>
      </div>
    );
  };
  
  