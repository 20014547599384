import Dashboard from './Dashboard'
import MaxWidthWrapper from '../../component/MaxWidthWrapper';
import { Link } from 'react-router-dom'
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useToast } from "../../component/ui/use-toast"
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { apiConnector } from '../../service/apiconnector';
import { Button } from '../../component/ui/button'
import { Icons } from '../../assests/Icons'
import { BiImage } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { setSignUpData } from "../../slices/otpSlice";
import { BiSolidUserCheck } from "react-icons/bi";
import { IoIosArrowBack } from "react-icons/io";
import { usePasswordChangeMutation } from '../../slices/apiSlices/carListingApiSlice'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../component/ui/form';
import { setUser } from '../../slices/profileSlice';
import { Input } from '../../component/ui/input';
// import './signin.css';

const signupSchema = z.object({
    username: z.string().min(3, 'Username shoud be at least 3 characters').max(30, 'Username is too long'),
    phone: z.string().regex(/^\d{10}$/, 'Phone number must be 10 digits'),
});
const changePasswordSchema = z.object({
    oldPassword: z.string().min(8, "Old password must be at least 8 characters"),
    newPassword: z.string().min(8, "New password must be at least 8 characters"),
});

const Signup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputRef = useRef()
    const { toast } = useToast()
    const { user } = useSelector((state) => state.profile);
    const { token } = useSelector((state) => state.auth);
    const { signUpData } = useSelector((state) => state.otp) || {};
    const email = signUpData?.email;
    const goBack = () => { navigate(-1); };
    const [PasswordChange, { isLoading }] = usePasswordChangeMutation();


    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false);

    const form = useForm({
        resolver: zodResolver(signupSchema),
        defaultValues: {
            username: user?.username || '',
            phone: user?.phone || '',
        },
    });

    const passwordForm = useForm({
        resolver: zodResolver(changePasswordSchema),
        defaultValues: {
            oldPassword: '',
            newPassword: '',
        },
    });


    const handleImageChange = (event) => {
        const file = event.target.files[0];

        setImage(file)

    };



    // const headers = {
    //   'Authorization': `Bearer ${token}`,
    // };

    const ChangePasswordHandler = async (data) => {
        try {


            // Send the change password request with old and new passwords
            const response = await PasswordChange({
                oldPassword: data.oldPassword,
                newPassword: data.newPassword,
                userId: user?.id
            }).unwrap();
            console.log(response)

            if (response.success) {
                toast({
                    title: "Password changed successfully",
                });
            } else {
                toast({
                    variant: "destructive",
                    title: response.message || "Failed to change password",
                });
            }

        } catch (error) {
            toast({
                variant: "destructive",
                title: error?.data?.message || "An error occurred",
            });
        }
    };



    const onSubmit = async (data) => {
        try {
            setLoading(true);

            const formData = { ...data, email: user?.email || email };
            const formDataToSend = new FormData();

            for (const key in formData) {
                formDataToSend.append(key, formData[key]);
            }

            if (image) {
                formDataToSend.append(`image`, image);
            }

            const result = await apiConnector('POST', process.env.REACT_APP_BASE_URL + "/api/profileupdate", formDataToSend);

            if (!result.data.success) {
                toast({
                    variant: "destructive",
                    title: result?.data?.message || "Failed to update",
                })

                throw new Error(result.data.message);
            }


            toast({
                title: "Profile Updated Successfully",
            })

            dispatch(setUser({
                ...user,  
                image: result?.data?.user?.image,  
                username: result?.data?.user?.username, 
                phone: result?.data?.user?.phone 
            }));
            

        } catch (error) {
            console.error("An error occurred:", error);
            toast({
                variant: "destructive",
                title: error.response?.data?.message || "Failed to update",
            })

        } finally {
            setLoading(false);
        }
    };

    return (


        <Dashboard>

            <header className="relative ">


                <div className="flex h-16 p-2 justify-between items-center">
                    <div className="ml-4 text-3xl font-inter font-bold flex lg:ml-0">
                        Settings
                    </div>


                </div>
            </header>

            <div className='w-full h-20 mt-4 bg-richblue-100 rounded-xl max-w-screen'>
                <div className='p-6 flex gap-2 items-center'>
                    <Button variant='secondary' onClick={goBack} className='font-bold p-0 text-foreground-muted h-10 w-10 rounded-md'>
                        <IoIosArrowBack className='text-lg text-muted-foreground' />
                    </Button>

                    <div className='text-xl font-bold font-inter text-white'>
                        Update your profile here!
                    </div>
                </div>
            </div>


            <div className='max-w-screen overflow-hidden font-inter'>
                <MaxWidthWrapper>


                    <div className=' grid sm:grid-cols-2  m-0'>


                        <div className='flex col-span-1 flex-col mt-10 justify-start items-start w-80'>

                            <div className='flex justify-center ml-4 items-center  gap-4'>
                                <div>
                                    <input id="file-upload" name="file" type="file" className="sr-only" ref={inputRef} multiple hidden onChange={handleImageChange} />
                                    {
                                        image || user?.image ? (<img src={image ? URL.createObjectURL(image) : user?.image} className='w-24 h-24 rounded-full object-cover object-center ' alt="" />) : (<Icons.photo className='w-24 h-24  rounded-full ' />)

                                    }

                                </div>

                                <div className='flex-col flex gap-4'>

                                    <Button variant='outline2' size='sm' className='border-[1px]' onClick={() => inputRef.current.click()}>
                                        <BiImage className='mr-2 ' />
                                        Change
                                    </Button>
                                    <Button variant='outline2' size='sm' className='border-[1px]' onClick={() => setImage(null)}>
                                        <AiOutlineDelete
                                            className='mr-2 ' />
                                        Remove
                                    </Button>
                                </div>
                            </div>

                            <div className='flex justify-center items-center mx-auto'>
                                <div className='m-10 '>
                                    <Form {...form} >
                                        <form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col font-inter space-y-4 text-gray-900 text-sm font-[400]' >



                                            <FormItem>
                                                <FormLabel className=' font-inter p-0 m-0'>Email</FormLabel>
                                                <div className="flex relative items-center">
                                                    <Input
                                                        type="text"
                                                        placeholder="Enter your username"
                                                        className='border-[1px] ring-0 font-semibold rounded-md p-2 w-72 focus:outline-0'
                                                        value={user?.email || ''} // Use an empty string if user?.email is undefined
                                                        readOnly // Optional: use this if you want the input to be read-only
                                                    />
                                                    {
                                                        user?.email && <div className='absolute inset-y-0 right-0 flex items-center pr-3'>
                                                            < BiSolidUserCheck className='text-richblue-100 text-lg' />
                                                        </div>
                                                    }

                                                </div>

                                            </FormItem>


                                            <FormField name="username" control={form.control}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel className=' font-inter p-0 m-0'>Username</FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                type="text"
                                                                placeholder="Enter your username"
                                                                className='border-[1px] ring-0 rounded-md p-2 w-72 focus:outline-0'
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />

                                            <FormField name="phone" control={form.control}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel className=' font-inter p-0 m-0'>Phone</FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                type="text"
                                                                placeholder="Enter your phone number"
                                                                className='border-[1px] ring-0 rounded-md p-2 w-72 focus:outline-0'
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />

                                                    </FormItem>
                                                )}
                                            />

                                            <div className='flex items-center justify-center mt-10'>
                                                <Button type="submit" disabled={loading} variant='btn' className='w-full py-0 px-0 '  > {loading ? <span className="loader"></span>
                                                    : 'Update Profile'}

                                                </Button>

                                            </div>
                                        </form>
                                    </Form>
                                </div>
                            </div>
                        </div>

                        <div className='col-span-1 mt-10'>

                            <div className='flex  flex-col items-center mx-auto'>
                                <div className='text-xl font-semibold font-inter'>
                                    change password
                                </div>
                                <div className='m-10'>
                                    <Form {...passwordForm}>
                                        <form onSubmit={passwordForm.handleSubmit(ChangePasswordHandler)} className='flex flex-col font-inter space-y-4 text-gray-900 text-sm font-[400]'>
                                            <FormField name="oldPassword" control={passwordForm.control}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel className='font-inter p-0 m-0'>Old Password</FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                type="password"
                                                                placeholder="Enter your old password"
                                                                className='border-[1px] ring-0 rounded-md p-2 w-72 focus:outline-0'
                                                                {...field}
                                                                autocomplete="old-password"

                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField name="newPassword" control={passwordForm.control}
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel className='font-inter p-0 m-0'>New Password</FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                type="password"
                                                                placeholder="Enter your new password"
                                                                className='border-[1px] ring-0 rounded-md p-2 w-72 focus:outline-0'
                                                                {...field}
                                                                autocomplete="new-password"

                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                            <div className='flex items-center justify-center mt-10'>
                                                <Button type="submit" disabled={isLoading} variant='btn' className='w-full py-0 px-0'>
                                                    {isLoading ? <span className="loader"></span> : "Change Password"}
                                                </Button>
                                            </div>
                                        </form>
                                    </Form>

                                </div>
                            </div>
                        </div>

                    </div>
                </MaxWidthWrapper>

            </div>

        </Dashboard>


    );
};

export default Signup;

