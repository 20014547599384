import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { Toaster } from './component/ui/toaster';
import ttlMiddleware from './middleware/ttlMiddleware';
import persistedReducer from './reducer/index.js';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { listingApiSlice } from './slices/apiSlices/carListingApiSlice';
import ErrorBoundary from './ErrorBoundry'; 
import { messaging } from './service/firebaseConfig';
import { requestNotificationPermission, requestFirebaseToken } from './service/firebaseMessaging';
import {useSelector} from 'react-redux'
import { SocketProvider } from "./lib/SocketContext";


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(listingApiSlice.middleware),
});

const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));

// if (process.env.REACT_APP_ENV === 'development') {
//   console.log = function () {};  
//   console.error = function () {};  
// }


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    const swUrl = `/firebase-messaging-sw.js`;
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

requestNotificationPermission()
  .then(() => requestFirebaseToken())
  .catch(error => console.error("Error requesting Firebase Token", error));


root.render(


 
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ErrorBoundary> {/* Wrap the entire app in the error boundary */}
      <SocketProvider>
        <BrowserRouter>
          <App />
          <Toaster />
        </BrowserRouter>
        </SocketProvider>
      </ErrorBoundary>
    </PersistGate>
  </Provider>

);

reportWebVitals();

